import { Avatar, Box, makeStyles, Typography } from '@material-ui/core';
import { string } from 'prop-types';
import React, { useState } from 'react';

const useStyles = makeStyles(() => ({
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  input: {
    display: 'none'
  }
}));

const ProfileInfo = ({ name, imageUrl, title, disabled }) => {
  const classes = useStyles();
  const [previewUrl, setPreviewUrl] = useState();

  const handleInputChange = (e) => {
    const image = e.target.files[0];
    setPreviewUrl(URL.createObjectURL(image));
  };

  return (
    <Box alignItems="center" display="flex" flexDirection="column" pb={2}>
      <input
        accept="image/png, image/jpg, image/jpeg"
        type="file"
        id="avatar"
        className={classes.input}
        disabled={disabled}
        onChange={handleInputChange}
      />
      <label htmlFor="avatar">
        <Avatar
          className={classes.avatar}
          component="span"
          alt={name}
          src={previewUrl || imageUrl}
        />
      </label>
      <Typography color="textPrimary" variant="h6">
        {name}
      </Typography>
      <Typography color="textSecondary" variant="body2">
        {title}
      </Typography>
    </Box>
  );
};

ProfileInfo.propTypes = {
  name: string,
  imageUrl: string,
  title: string
};

export default ProfileInfo;
