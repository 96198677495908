import React from 'react';
import useAllUsers from '../firestore/useAllUsers';
import PinnedSubheaderList from '../views/users/List';

const UsersList = (props) => {
  const { users } = useAllUsers();
  return <PinnedSubheaderList data={users} {...props} />;
};

export default UsersList;
