import { useEffect, useState } from 'react';
import { db } from '../firebase';

const useNutritionPlanMeals = (id) => {
  const [data, setData] = useState({
    error: null,
    loading: true,
    meals: []
  });

  useEffect(() => {
    const nutritionPlanRef = db.collection('nutrition_plans').doc(id);

    const unsubscribe = nutritionPlanRef
      .collection('meals')
      .orderBy('index', 'asc')
      .onSnapshot((snapshot) => {
        const meals = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }));

        setData({
          loading: false,
          error: null,
          meals
        });
      });

    return () => unsubscribe();
  }, []);

  return data;
};

export default useNutritionPlanMeals;
