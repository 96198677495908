import { createSlice } from '@reduxjs/toolkit';

export const filterSlice = createSlice({
  name: 'filter',
  initialState: {
    filters: []
  },
  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload;
    }
  }
});

const { actions, reducer } = filterSlice;
export const { setFilters } = actions;

export default reducer;
