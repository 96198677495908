import { IconButton } from '@material-ui/core';
import { default as ArrowBackIcon } from '@material-ui/icons/ArrowBack';
import { func } from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const BackButton = ({ onClick }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1);
  };

  return (
    <IconButton color="primary" onClick={onClick ?? handleClick}>
      <ArrowBackIcon color="primary" />
    </IconButton>
  );
};

BackButton.propTypes = {
  onClick: func
};

export default BackButton;
