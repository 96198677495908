import _ from 'lodash';
import { useEffect, useState } from 'react';
import app, { db } from '../firebase';

const useExercises = () => {
  const [data, setData] = useState({
    error: null,
    loading: true,
    exercises: []
  });

  useEffect(() => {
    const unsubscribe = db.collection('exercises').onSnapshot((snapshot) => {
      const exercises = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));

      setData({
        loading: false,
        error: null,
        exercises
      });
    });

    return () => unsubscribe();
  }, []);

  return data;
};

export default useExercises;
