import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { string } from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  subtitle: {
    color: theme.palette.text.hint,
    fontSize: 14,
    fontWeight: '500'
  },
  container: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(4)
  }
}));

const ViewHeader = ({ title, subtitle, containerStyle }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.container, containerStyle)}>
      <Box display="flex" justifyContent="space-between" alignItems="flex-end">
        <Typography variant="h1">{title}</Typography>
        <Typography className={classes.subtitle}>{subtitle}</Typography>
      </Box>
      <Box mt={2} />
    </div>
  );
};

ViewHeader.propTypes = {
  title: string,
  subtitle: string,
  containerStyle: string
};

export default ViewHeader;
