import { Box, Button, Dialog, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import app from '../../firebase';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiPaper-rounded': {
      borderRadius: 12
    }
  },
  dialog: {
    padding: theme.spacing(2),
    background: theme.palette.background.lightBlue,
    // width: 550,
    padding: '70px 54px 54px 54px'
  },
  title: {
    color: theme.palette.secondary.main,
    maxWidth: 430
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  iconBackground: {
    width: '140px',
    height: '140px',
    borderRadius: '50%',
    backgroundColor: '#EFF3F9',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  waveIcon: {
    fontSize: '60px'
  },
  textContainer: {
    margin: '32px',
    '& > *': {
      textAlign: 'center'
    }
  },
  title: {
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '32px'
  },
  text: {
    marginTop: '8px',
    fontSize: '14px',
    color: '#5B5971'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  primaryButton: {
    borderRadius: 12,
    color: theme.palette.common.white,
    fontWeight: 700,
    fontSize: 12,
    padding: theme.spacing(2),
    width: 216,
    height: 48
  },
  secondaryButton: {
    borderRadius: 12,
    background: theme.palette.common.white,
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: 12,
    padding: '18px',
    width: 216,
    // border: '2px solid #1BCB99',
    height: 48
  }
}));

const LogoutModal = ({ handleClose, open }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const signOut = () => app.auth().signOut();

  const handleSignOut = async () => {
    try {
      // TODO - fix signout when authentication is implemented
      await signOut();
      navigate('/login');
    } catch (error) {}
  };

  return (
    <Dialog open={open} className={classes.root}>
      <div className={classes.dialog}>
        <Box className={classes.iconContainer}>
          <Box className={classes.iconBackground}>
            <Typography className={classes.waveIcon}>👋</Typography>
          </Box>
        </Box>
        <Box className={classes.textContainer}>
          <Typography className={classes.title}>{t('logout')}</Typography>
          <Typography variant="body2" className={classes.text}>
            {t('logoutConfirmation')}
          </Typography>
        </Box>
        <div className={classes.buttons}>
          <Button
            disableElevation
            className={classes.primaryButton}
            variant="contained"
            color="primary"
            onClick={handleClose}
          >
            {t('remove')}
          </Button>
          <Box mx={1} />
          <Button
            disableElevation
            className={classes.secondaryButton}
            variant="contained"
            onClick={handleSignOut}
          >
            {t('logout')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

LogoutModal.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool
};

export default LogoutModal;
