/* eslint-disable no-use-before-define */
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MUIAutocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { useController } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  label: {
    display: 'block'
  },
  textfield: {
    borderRadius: 8,
    background: 'white',
    border: `1px solid ${theme.palette.primary.surface}`,
    // border: `1px solid red`,
    padding: '0.5rem',
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 400,
    '& input::placeholder': {
      color: theme.palette.text.hint
      // opacity: 1
    }
  },
  input: {
    width: 200
  },
  listbox: {
    width: 200,
    margin: 0,
    padding: 0,
    zIndex: 1,
    position: 'absolute',
    listStyle: 'none',
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
    maxHeight: 200,
    border: '1px solid rgba(0,0,0,.25)',
    '& li[data-focus="true"]': {
      backgroundColor: '#4a8df6',
      color: 'white',
      cursor: 'pointer'
    },
    '& li:active': {
      backgroundColor: '#2977f5',
      color: 'white'
    }
  }
}));

const Autocomplete = ({
  name,
  control,
  rules,
  defaultValue,
  setValue,
  data,
  ...props
}) => {
  const classes = useStyles();
  const {
    field: { onChange, onBlur, ref },
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    rules,
    defaultValue
  });

  // if (isLoading) {
  //   return <div />;
  // }

  const defaultProps = {
    options: data,
    getOptionLabel: (option) => option?.title ?? 'No title'
  };

  return (
    <MUIAutocomplete
      {...defaultProps}
      id="auto-complete"
      autoComplete
      defaultValue={data?.[0]}
      inputValue={defaultValue}
      clearOnBlur={false}
      includeInputInList
      onChange={(event, newValue) => {
        if (!newValue) {
          return;
        }
        Object.keys(newValue).forEach((key) =>
          setValue(key, newValue[key], { shouldValidate: true })
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          {...props}
          onBlur={onBlur}
          onChange={onChange}
          inputRef={ref}
          name={name}
          className={classes.textfield}
          InputProps={{ ...params.InputProps, disableUnderline: true }}
        />
      )}
    />
  );
};

export default Autocomplete;
