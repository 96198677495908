import { Dialog, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiPaper-rounded': {
      borderRadius: 12
    }
  },
  dialog: {
    background: theme.palette.background.lightBlue,
    padding: theme.spacing(9, 7, 7, 7)
  }
}));

const BaseDialog = ({ open, children }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} className={classes.root}>
      <div className={classes.dialog}>{children}</div>
    </Dialog>
  );
};

BaseDialog.propTypes = {
  open: PropTypes.bool
};

export default BaseDialog;
