import { useState } from 'react';

export const useLoading = () => {
  const [isLoading, setLoading] = useState(false);

  const withLoading = async (promise) => {
    try {
      setLoading(true);

      const result = await promise();
      setLoading(false);
      return result;
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  return {
    isLoading,
    withLoading
  };
};
