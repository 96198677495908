import { Box, Container, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import WorkoutPlansList from '../../components/EventList';
import Page from '../../components/Page';
import PlanModal from '../../components/PlanModal/PlanModal';
import { addNewPlan } from '../../features/workoutplans/slice';
import useWorkoutPlans from '../../firestore/useWorkoutPlans';
import useToggle from '../../hooks/useToggle';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.lightBlue,
    minHeight: '100%',
    height: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

const WorkoutsView = () => {
  const classes = useStyles();
  const [open, toggleOpen] = useToggle(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const { data: workoutPlans } = useWorkoutPlans();
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedItem) {
      handleClickOpen();
    }
  }, [selectedItem]);

  const handleClickOpen = () => {
    toggleOpen();
  };

  const handleClose = () => {
    toggleOpen();
    setSelectedItem(null);
  };

  const handleAddClass = () => {
    dispatch(addNewPlan());
    handleClickOpen();
  };

  return (
    <Page className={classes.root} title="Customers">
      <Container>
        <Box>
          <WorkoutPlansList onAddClass={handleAddClass} data={workoutPlans} />
        </Box>

        {open && <PlanModal isOpen={true} handleClose={handleClose} />}
      </Container>
    </Page>
  );
};

export default WorkoutsView;
