import { Box, makeStyles } from '@material-ui/core';
import { func, string } from 'prop-types';
import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';

const useStyles = makeStyles((theme) => ({
  player: {
    position: 'relative',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: '100%',
    // height: 412,
    borderRadius: 12
  }
}));

const VideoPlayer = ({ url, onVideoPlayerStateChanged }) => {
  const classes = useStyles();

  return (
    <Box ml={3} mt={2} height={360}>
      <ReactPlayer
        style={{ overflow: 'hidden', borderRadius: 8 }}
        controls={true}
        width="100%"
        height="100%"
        // url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
        // url="https://www.youtube.com/watch?v=UKwgBviSotU"
        url={url}
        onReady={(player) => onVideoPlayerStateChanged(null, player)}
        onError={(error) => onVideoPlayerStateChanged(error, null)}
      />
    </Box>
  );
};

VideoPlayer.propTypes = {
  videoId: string,
  setVideoDuration: func
};

export default VideoPlayer;
