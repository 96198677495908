import { Box, CircularProgress, makeStyles } from '@material-ui/core/';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// import UploadImage from '../../UploadImage';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ActionModal from '../ActionModal/ActionModal';
import Modal from '../Modal';
import UsersList from '../UsersList';

const useStyles = makeStyles((theme) => ({
  subTitle: {
    marginTop: theme.spacing(3)
  },
  textFieldSingle: {
    display: 'block',
    // marginTop: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    width: '100%',
    borderRadius: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    height: 52
  },
  textFieldMulti: {
    height: 'auto',
    width: '100%'
  },
  inputRoot: {
    display: 'flex'
  },
  inputText: {
    fontSize: 16,
    fontWeight: 400
    // paddingTop: 15
  },
  counterText: {
    color: theme.palette.grey[500],
    marginBottom: 140
  }
}));

const AddUserModal = ({
  isOpen,
  handleClose,
  handleSave,
  onAddUser,
  // isLoading,
  defaultValues,
  addUser
}) => {
  const [user, setUser] = useState(null);
  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState(null);
  const { selectedPlan, isLoading } = useSelector((state) => state.plans);
  const {
    handleSubmit,
    control,
    watch,
    trigger,
    reset,
    register,
    formState: { isValid, isDirty, errors }
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: defaultValues || {
      title: '',
      description: '',
      imageUrl: ''
    }
  });

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onSave = () => {
    handleSave({ file: selectedFile });
  };

  const handleListItemClick = ({ user }) => {
    setUser(user);
  };

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      handleSave={onSave}
      title={t('add user')}
      isLoading={isLoading}
      disabled={!selectedPlan?.isValid}
    >
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flex={1}
        >
          <CircularProgress />
        </Box>
      ) : (
        <UsersList onListItemClick={handleListItemClick} />
      )}
      {!!user && (
        <ActionModal
          open={!!user}
          title={t('add', { name: user?.name?.first })}
          // description={t('add user desc')}
          description=""
          avatar={user?.picture?.large}
          onSecondaryAction={() => setUser(null)}
          onPrimaryAction={() => onAddUser({ user })}
          primaryButtonText={t('confirm')}
        />
      )}
    </Modal>
  );
};

AddUserModal.propTypes = {
  introText: PropTypes.string,
  isOpen: PropTypes.bool,
  isEditing: PropTypes.bool,
  children: PropTypes.instanceOf(Element),
  handleClose: PropTypes.func,
  handleSave: PropTypes.func,
  onAddUser: PropTypes.func,
  onSave: PropTypes.func,
  addUser: PropTypes.func,
  state: PropTypes.object
};

export default AddUserModal;
