import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  primaryButton: {
    borderRadius: 12,
    color: theme.palette.common.white,
    fontWeight: 'bold',
    fontSize: 12,
    padding: theme.spacing(2),
    width: '100%',
    height: 48
  },
  secondaryButton: {
    background: theme.palette.common.white,
    color: theme.palette.primary.main
  },
  errorButton: {
    background: theme.palette.error.main
  }
}));

const PrimaryButton = ({ secondary, isLoading, error, ...props }) => {
  const classes = useStyles();
  return (
    <Button
      disableElevation
      className={clsx(
        classes.primaryButton,
        secondary ? classes.secondaryButton : null,
        error ? classes.errorButton : null
      )}
      variant="contained"
      color={secondary ? undefined : 'primary'}
      {...props}
    >
      {isLoading ? (
        <CircularProgress size={24} color="inherit" />
      ) : (
        props.children
      )}
    </Button>
  );
};

export default PrimaryButton;
