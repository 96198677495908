import { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';

const useFirebaseSMSAuth = () => {
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [verifier, setVerifier] = useState(null);

  useEffect(() => {
    const verifier = new firebase.auth.RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible'
      }
    );
    setVerifier(verifier);
    return () => verifier.clear();
  }, []);

  const sendVerificationCode = async (phoneNumber) => {
    try {
      const provider = new firebase.auth.PhoneAuthProvider();
      const verificationId = await provider.verifyPhoneNumber(
        phoneNumber,
        verifier
      );
      return verificationId;
    } catch (err) {
      setError(err);
    }
  };

  const signInWithVerificationCode = async (
    verificationCode,
    verificationId
  ) => {
    try {
      const credential = firebase.auth.PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );
      const userCredential = await firebase
        .auth()
        .signInWithCredential(credential);
      setUser(userCredential.user);
    } catch (err) {
      setError(err);
    }
  };

  return {
    sendVerificationCode,
    signInWithVerificationCode,
    error,
    user
  };
};

export default useFirebaseSMSAuth;
