import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#000000',
      surface: '#EFF3F9'
    },
    secondary: {
      main: '#7672ff'
    },
    background: {
      lightBlue: '#F8FAFD'
    },
    text: {
      hint: '#85839B'
    }
  },
  zIndex: {
    mobileStepper: 3000,
    appBar: 3200,
    drawer: 3100,
    modal: 3300,
    snackbar: 3400,
    tooltip: 3500
  },
  typography: {
    fontFamily: ['Inter'],
    h1: {
      fontWeight: 600,
      fontSize: 39,
      color: '#181441'
    },
    h2: {
      fontWeight: 500,
      fontSize: 29,
      color: '#181441'
    },
    h3: {
      fontWeight: 600,
      fontSize: 24,
      color: '#85839B'
    },
    h4: {
      fontWeight: 600,
      fontSize: 18,
      color: '#181441'
    },
    h5: {
      fontWeight: 700,
      fontSize: 14,
      color: '#181441'
    },
    h6: {
      fontWeight: 600,
      fontSize: 14,
      color: '#181441'
    },
    body1: {
      fontWeight: 400,
      fontSize: 16,
      color: '#181441'
    },

    body2: {
      fontSize: 12,
      color: '#85839B',
      fontFamily: 'Inter'
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: 14,
      color: '#85839B',
      textTransform: 'none'
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: 14,
      color: '#181441'
    },
    overline: {
      fontWeight: 500,
      color: '#181441'
    }
  },
  shadows: [
    'none',
    '0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 2px 2px -2px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 3px 4px -2px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 3px 4px -2px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 4px 6px -2px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 4px 6px -2px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 4px 8px -2px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 5px 8px -2px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 6px 12px -4px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 7px 12px -4px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 6px 16px -4px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 7px 16px -4px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 8px 18px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 9px 18px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 10px 20px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 11px 20px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 12px 22px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 13px 22px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 14px 24px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 16px 28px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 18px 30px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 20px 32px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 22px 34px -8px rgba(0,0,0,0.25)',
    '0 0 1px 0 rgba(0,0,0,0.31), 0 24px 36px -8px rgba(0,0,0,0.25)'
  ],
  dropShadows: ['0px 8px 24px rgba(24, 20, 65, 0.08)']
});

export default theme;
