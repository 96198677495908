import {
  Box,
  Button,
  ButtonBase,
  Divider,
  Drawer,
  Hidden,
  List,
  makeStyles,
  Typography
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ActionModal from '../../../components/ActionModal/ActionModal';
import { updateUser } from '../../../features/users/usersSlice';
import app from '../../../firebase';
import { useUser } from '../../../hooks/useUser';
import NutritionIcon from '../../../icons/NutritionIcon';
import UsersIcon from '../../../icons/UsersIcon';
import WorkoutsIcon from '../../../icons/WorkoutsIcon';
import NavItem from './NavItem';

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 310
  },
  desktopDrawer: {
    width: 310,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  input: {
    display: 'none'
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  avatarContainer: {
    borderRadius: theme.spacing(4)
  },
  name: {}
}));

const NavBar = ({
  onMobileClose,
  openMobile,
  onSignOut,
  children,
  variant
}) => {
  const [currentUser] = useUser();
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const [imageAsFile, setImageAsFile] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const dispatch = useDispatch();
  const inputRef = useRef();

  const items = [
    {
      href: '/app/users',
      icon: UsersIcon,
      title: t('users')
    },
    {
      href: '/app/workouts',
      icon: WorkoutsIcon,
      title: t('workouts')
    },
    {
      href: '/app/nutrition',
      icon: NutritionIcon,
      title: t('nutrition')
    }
  ];

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (imageAsFile) {
      handleSubmitFile();
    }
  }, [imageAsFile]);

  const uploadImage = () => {
    const storageRef = app.storage().ref().child(`images/${currentUser.id}`);
    const uploadTask = storageRef.put(imageAsFile);

    uploadTask.on(
      'state_changed',
      (snapshot) => {},
      (err) => {},
      async () => {
        const downloadUrl = await uploadTask.snapshot.ref.getDownloadURL();

        const dataToUpdate = {
          picture: {
            thumbnail: downloadUrl
          }
        };

        dispatch(updateUser({ id: currentUser.id, ...dataToUpdate }));
      }
    );
  };

  const handleSubmitFile = () => {
    if (imageAsFile === '') {
      console.error(`not an image, the image file is a ${typeof imageAsFile}`);
      return;
    }
    uploadImage();
    setProfileImageLocally();
  };

  const setProfileImageLocally = () => {
    const reader = new FileReader();
    reader.readAsDataURL(imageAsFile);
    reader.onloadend = () => {
      setSelectedImage(reader.result);
    };
    reader.onerror = () => {};
  };

  const handleFileInputChange = (e) => {
    const image = e.target.files[0];
    setImageAsFile(image);
  };

  const handleAvatarClick = () => inputRef.current.click();

  const renderContent = () => (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <input
          accept="image/*"
          type="file"
          id="avatar"
          className={classes.input}
          onChange={handleFileInputChange}
          ref={inputRef}
        />
        <ButtonBase
          onClick={handleAvatarClick}
          className={classes.avatarContainer}
        >
          <Avatar
            className={classes.avatar}
            component="span"
            src={selectedImage || currentUser?.picture?.thumbnail}
          />
        </ButtonBase>
        <Typography className={classes.name} color="textPrimary" variant="h6">
          {currentUser?.name?.full || currentUser?.email}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {t('personal trainer')}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
      <Box p={2}>
        <Button className={classes.button} onClick={onSignOut}>
          <span className={classes.title}>{t('logout')}</span>
        </Button>
      </Box>
    </Box>
  );

  if (!currentUser) {
    return null;
  }

  return (
    <>
      <Drawer
        anchor="left"
        classes={{ paper: classes.mobileDrawer }}
        onClose={onMobileClose}
        open={openMobile}
        variant={variant || 'temporary'}
        // variant="persistent"
      >
        {renderContent()}
      </Drawer>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {renderContent()}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
