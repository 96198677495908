import React, { useContext } from 'react';
import { Route, Navigate } from 'react-router-dom';
import { AuthContext } from './Authentication';

//eslint-disable-next-line
const AuthorizedRoute = ({ ...rest }) => {
  const { currentUser } = useContext(AuthContext);
  if (!!currentUser) {
    return <Route {...rest} />;
  }
  return <Navigate to="/login" />;
};

export default AuthorizedRoute;
