import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import { nanoid } from 'nanoid';
import { db } from '../../firebase';
import { FALLBACK_URLS, createThumbnailUrl, reorderList } from '../../utils';

export const addPlan = createAsyncThunk(
  'workoutPlans/add',
  async ({ name, description }) => {
    // const { key } = user;
    // const batch = db.batch();

    // const userRef = db.collection('users').doc(key);
    // const userWorkoutPlanRef = userRef.collection('workouts').doc(workout.id);

    // batch.set(userWorkoutPlanRef, {
    //   ...workout,
    //   isCurrentWorkout: true,
    //   createdAt: firebase.firestore.Timestamp.now()
    // });
    // batch.set(userRef, { workoutPlanId: workout.id }, { merge: true });

    return batch.commit();
  }
);

export const addUserWorkout = createAsyncThunk(
  'workoutPlans/addUserPlan',
  async ({ plan, user }) => {
    const { key } = user;
    const batch = db.batch();
    const { workouts, ...rest } = plan;

    const userRef = db.collection('users').doc(key);
    const userPlanRef = userRef.collection('workout_plans').doc(plan.id);

    const userWorkoutPlan = {
      ...rest,
      createdAt: firebase.firestore.Timestamp.now(),
      workouts: workouts.map(({ id, index, thumbnail, title }) => ({
        id,
        index,
        thumbnail,
        title
      }))
    };
    batch.set(userPlanRef, userWorkoutPlan, { merge: true });

    batch.set(userRef, { workoutPlanId: plan.id }, { merge: true });

    workouts.forEach((workout) => {
      const { exercises, ...rest } = workout;
      const workoutRef = userPlanRef.collection('workouts').doc(workout.id);
      batch.set(workoutRef, rest);

      exercises.forEach((item) => {
        const { isValid, ...exercise } = item;
        const exerciseRef = workoutRef.collection('exercises').doc(exercise.id);
        batch.set(exerciseRef, exercise);
      });
    });

    return batch.commit();
  }
);

export const reorderWorkouts = createAsyncThunk(
  'workoutPlans/reorderWorkouts',
  async ({ workouts }, { getState }) => {
    const state = getState();
    const planId = state.plans.selectedPlan.id;
    const batch = db.batch();

    const planRef = db.collection('workout_plans').doc(planId);

    workouts.forEach((workout) => {
      const docRef = planRef.collection('workouts').doc(workout.id);
      batch.set(docRef, { index: workout.newIndex }, { merge: true });
    });

    try {
      await batch.commit();
      return { error: null };
    } catch (error) {
      return { error };
    }
  }
);

export const deletePlan = createAsyncThunk(
  'workoutPlans/deletePlan',
  async (_, { getState }) => {
    const planId = getState().plans.selectedPlan.id;
    const batch = db.batch();

    const planRef = db.collection('workout_plans').doc(planId);

    batch.delete(planRef);

    return batch.commit();
  }
);

export const deleteWorkoutById = createAsyncThunk(
  'workoutPlans/deleteWorkout',
  async ({ planId, workoutId }) => {
    const batch = db.batch();

    const planRef = db.collection('workout_plans').doc(planId);

    const docRef = planRef.collection('workouts').doc(workoutId);

    batch.delete(docRef);
    batch.update(planRef, {
      workoutCount: firebase.firestore.FieldValue.increment(-1)
    });

    try {
      await batch.commit();
      return { error: null, workoutId };
    } catch (error) {
      return { error, workoutId };
    }
  }
);
export const fetchWorkoutsById = createAsyncThunk(
  'workoutPlans/fetchById',
  async (planId) => {
    const promise = db
      .collection('workout_plans')
      .doc(planId)
      .collection('workouts')
      .get()
      .then((snapshot) => {
        return snapshot.docs.map((item) => ({
          id: item.id,
          ...item.data(),
          planId
        }));
      });
    return await promise;
  }
);

export const fetchWorkoutExcercises = createAsyncThunk(
  'workoutPlans/fetchByWorkout',
  async ({ planId, workoutId }) => {
    const promise = db
      .collection('workout_plans')
      .doc(planId)
      .collection('workouts')
      .doc(workoutId)
      .collection('exercises')
      .get()
      .then((snapshot) =>
        snapshot.docs.map((item) => ({
          id: item.id,
          ...item.data(),
          planId,
          workoutId
        }))
      );
    return await promise;
  }
);

export const deleteWorkoutSection = createAsyncThunk(
  'workoutPlans/deleteSection',
  async (payload, { getState, dispatch }) => {
    dispatch(deleteSection(payload));
    dispatch(validateWorkout());

    return true;
  }
);

export const saveWorkout = createAsyncThunk(
  'workoutPlans/saveWorkout',
  async (_, { getState }) => {
    const state = getState();
    const plan = state.plans.selectedPlan;
    const { selectedWorkout } = plan;
    const { title, url, index } = selectedWorkout;
    const exercises = selectedWorkout?.exercises;

    const shouldUpdateWorkout = plan.workouts.some(
      (item) => item.id === selectedWorkout.id
    );

    const batch = db.batch();

    const planDoc = db.collection('workout_plans').doc(plan.id);
    const workoutsCollection = planDoc.collection('workouts');

    const doc = shouldUpdateWorkout
      ? workoutsCollection.doc(selectedWorkout.id)
      : workoutsCollection.doc();

    batch.set(doc, {
      title,
      exercises,
      url: url ?? FALLBACK_URLS.WORKOUT,
      index,
      thumbnail: FALLBACK_URLS.WORKOUT
    });
    if (!shouldUpdateWorkout) {
      batch.update(planDoc, {
        workoutCount: firebase.firestore.FieldValue.increment(1),
        thumbnail: FALLBACK_URLS.WORKOUT
      });
    }

    if (exercises[1]) {
      const thumbnail = FALLBACK_URLS.WORKOUT;

      if (thumbnail) {
        batch.set(
          planDoc,
          {
            thumbnail
          },
          { merge: true }
        );
      }
    }

    await batch.commit();
    return {
      ...plan.selectedWorkout,
      id: doc.id,
      thumbnail:
        'https://res.cloudinary.com/alvar-aronija/image/upload/v1632554088/gymply/dumbbell-1966247_1280_xazzgq.jpg'
    };
  }
);

export const slice = createSlice({
  name: 'workoutPlans',
  initialState: {
    selectedPlan: null,
    isSaved: false,
    isLoading: false
  },
  reducers: {
    setSearchText: (state, action) => {
      state.searchText = action.payload;
    },
    setSelectedPlan: (state, action) => {
      state.selectedPlan = action.payload;
    },
    setSelectedExercise: (state, { payload }) => {
      // const result = state.selectedPlan.selectedWorkout.exercises.find(
      //   (item) => item.id === payload.id
      // );
      state.selectedPlan.selectedWorkout.selectedExercise = payload;
    },
    deleteSection: (state, { payload }) => {
      state.selectedPlan.selectedWorkout.exercises =
        state.selectedPlan.selectedWorkout.exercises.filter((item) => {
          return item.id !== payload.id;
        });
      state.selectedPlan.selectedWorkout.selectedExercise =
        state.selectedPlan.selectedWorkout.exercises[payload.index - 1];
    },
    addSection: (state) => {
      const newExc = {
        id: nanoid(),
        title: ''
      };

      state.selectedPlan.selectedWorkout.exercises.push(newExc);
      state.selectedPlan.selectedWorkout.selectedExercise = newExc;
    },
    updateSection: (state, { payload }) => {
      if (payload.index === 0) {
        state.selectedPlan.selectedWorkout.title = payload.title;
        state.selectedPlan.selectedWorkout.url = payload.url;
        state.selectedPlan.selectedWorkout.description = payload.description;
        state.selectedPlan.selectedWorkout.exercises[0].title = payload.title;
        state.selectedPlan.selectedWorkout.exercises[0].url = payload.url;
        state.selectedPlan.selectedWorkout.exercises[0].thumbnail =
          createThumbnailUrl(payload.url);
        state.selectedPlan.selectedWorkout.exercises[0].description =
          payload.description;
        state.selectedPlan.selectedWorkout.selectedExercise.title =
          payload.title;
      } else {
        state.selectedPlan.selectedWorkout.exercises[payload.index] = payload;
      }
    },
    deleteSelectedWorkoutUrl: (state, { payload }) => {
      state.selectedPlan.selectedWorkout.url = payload.url;
      state.selectedPlan.selectedWorkout.exercises[0].url = payload.url;
    },
    setSelectedWorkout: (state, { payload }) => {
      if (!payload) {
        state.selectedPlan.selectedWorkout = null;
        return;
      }

      const result = state.selectedPlan?.workouts?.find(
        (item) => item.id === payload
      );

      state.selectedPlan.selectedWorkout = result;
      if (result) {
        state.selectedPlan.selectedWorkout.isValid = true;
        if (result.exercises.length === 0) {
          result.exercises.push({
            id: 'info',
            title: state.selectedPlan.selectedWorkout.title,
            url: result.url
          });
        }
        state.selectedPlan.selectedWorkout.selectedExercise =
          result.exercises[0];
      }
    },
    setWorkoutValidation: (state, { type, payload }) => {
      var exc = state.selectedPlan.selectedWorkout.exercises.find(
        (item) => item.id === payload.id
      );
      exc.isValid = payload.isValid;
      state.selectedPlan.selectedWorkout.selectedExercise.isValid =
        payload.isValid;
      state.selectedPlan.selectedWorkout.isValid =
        state.selectedPlan.selectedWorkout.exercises.every(
          (item) => item.isValid
        );
    },
    validateWorkout: (state, action) => {
      state.selectedPlan.selectedWorkout.isValid =
        state.selectedPlan.selectedWorkout.exercises.every(
          (item) => item.isValid
        );
    },
    saveSuccess: (state, { payload }) => {
      state.isSaved = true;
    },
    reorderSections: (state, { payload }) => {
      state.selectedPlan.selectedWorkout.exercises = reorderList(
        state.selectedPlan.selectedWorkout.exercises,
        payload.startIndex,
        payload.endIndex
      );
    },
    addNewWorkout: (state) => {
      const index = state.selectedPlan.workouts.length + 1;
      state.selectedPlan.selectedWorkout = {
        id: nanoid(),
        title: '',
        exercises: [],
        isValid: false,
        index
      };
    },
    addNewPlan: (state) => {
      // const index = state.plans.length + 1;
      state.selectedPlan = {
        id: nanoid(),
        title: '',
        exercises: [],
        isValid: false,
        imageUrl: ''
        // index
      };
    },
    updateSelectedPlan: (
      state,
      { payload: { title, description, isValid } }
    ) => {
      state.selectedPlan.title = title;
      state.selectedPlan.description = description;
      state.selectedPlan.isValid = isValid;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(addUserWorkout.pending, (state, { type, payload }) => {
      state.isLoading = true;
    });
    builder.addCase(addUserWorkout.fulfilled, (state, { payload, type }) => {
      state.isLoading = false;
    });

    builder.addCase(addUserWorkout.rejected, (state, action) => {
      console.log('addUserWorkout.rejected', action.error.message);
    });

    builder.addCase(fetchWorkoutsById.fulfilled, (state, action) => {
      state.selectedPlan.workouts = action.payload;
    });

    builder.addCase(fetchWorkoutExcercises.fulfilled, (state, action) => {
      state.selectedPlan.selectedWorkout.selectedExercise = information;
      state.selectedPlan.selectedWorkout.exercises = [...action.payload];
    });

    builder.addCase(saveWorkout.fulfilled, (state, { payload }) => {
      const index = state.selectedPlan.workouts.findIndex(
        (item) => item.id === payload.id
      );

      if (index !== -1) {
        state.selectedPlan.workouts[index] = payload;
      } else {
        state.selectedPlan.workouts.push(payload);
      }
      state.isLoading = false;
      state.isSaved = false;
    });

    builder.addCase(saveWorkout.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(saveWorkout.rejected, (state, error) => {
      console.log('saveWorkout.rejected', error);
      state.isLoading = false;
    });

    builder.addCase(deleteWorkoutById.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(deleteWorkoutById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedPlan.workouts = state.selectedPlan.workouts.filter(
        (item) => item.id !== action.payload.workoutId
      );
    });

    builder.addCase(deletePlan.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(deletePlan.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedPlan = null;
    });

    builder.addCase(deletePlan.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(addPlan.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(addPlan.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedPlan = action.payload;
    });

    builder.addCase(addPlan.rejected, (state, action) => {
      state.isLoading = false;
    });
  }
});

const { actions, reducer } = slice;
export const {
  addSection,
  setSearchText,
  setSelectedPlan,
  setSelectedExercise,
  deleteSection,
  validateWorkout,
  updateSection,
  setSelectedWorkout,
  addNewWorkout,
  saveSuccess,
  reorderSections,
  setWorkoutValidation,
  updateSelectedPlan,
  addNewPlan,
  deleteSelectedWorkoutUrl
} = actions;

export default reducer;
