import { Avatar, Box, Dialog, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../PrimaryButton/PrimaryButton';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiPaper-rounded': {
      borderRadius: 12
    }
  },
  dialog: {
    padding: theme.spacing(2),
    background: theme.palette.background.lightBlue,
    padding: '70px 54px 54px 54px'
  },
  title: {
    color: theme.palette.secondary.main,
    maxWidth: 430
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  iconBackground: {
    width: '140px',
    height: '140px',
    borderRadius: '50%',
    backgroundColor: '#EFF3F9',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  waveIcon: {
    fontSize: '60px'
  },
  textContainer: {
    margin: '32px',
    '& > *': {
      textAlign: 'center'
    }
  },
  title: {
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '32px'
  },
  text: {
    marginTop: '8px',
    fontSize: '14px',
    color: '#5B5971'
  },
  buttons: {
    display: 'flex',
    minWidth: 332,
    justifyContent: 'space-between'
  },
  primaryButton: {
    borderRadius: 12,
    color: theme.palette.common.white,
    fontWeight: 700,
    fontSize: 12,
    padding: theme.spacing(2),
    width: 216,
    height: 48
  },
  secondaryButton: {
    borderRadius: 12,
    background: theme.palette.common.white,
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: 12,
    padding: '18px',
    width: 216,
    height: 48
  },
  avatar: {
    width: theme.spacing(18),
    height: theme.spacing(18)
  }
}));

const ActionModal = ({
  open,
  onPrimaryAction,
  onSecondaryAction,
  primaryButtonText,
  secondaryButtonText,
  title,
  description,
  emoji,
  avatar,
  isLoading
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog open={open} className={classes.root}>
      <div className={classes.dialog}>
        <Box className={classes.iconContainer}>
          {avatar ? (
            <Avatar src={avatar} className={classes.avatar} />
          ) : (
            <Box className={classes.iconBackground}>
              <Typography className={classes.waveIcon}>
                {emoji ?? '👋'}
              </Typography>
            </Box>
          )}
        </Box>
        <Box className={classes.textContainer}>
          <Typography className={classes.title}>
            {title ?? t('logout')}
          </Typography>
          <Typography variant="body2" className={classes.text}>
            {description ?? t('logoutConfirmation')}
          </Typography>
        </Box>
        <div className={classes.buttons}>
          <PrimaryButton onClick={onSecondaryAction}>
            {secondaryButtonText ?? t('remove')}
          </PrimaryButton>
          {onPrimaryAction && (
            <>
              <Box mx={1} />
              <PrimaryButton
                isLoading={isLoading}
                secondary
                onClick={onPrimaryAction}
              >
                {primaryButtonText ?? t('logout')}
              </PrimaryButton>
            </>
          )}
        </div>
      </div>
    </Dialog>
  );
};

ActionModal.propTypes = {
  onPrimaryAction: PropTypes.func,
  open: PropTypes.bool,
  avatar: PropTypes.string,
  isLoading: PropTypes.bool
};

ActionModal.defaultProps = {
  // avatar: 'https://randomuser.me/api/portraits/men/15.jpg'
};

export default ActionModal;
