import {
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography
} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';
import { default as PersonAdd } from '@material-ui/icons/PersonAdd';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import app from '../../firebase';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // height: '80vh',
    position: 'relative',
    overflow: 'auto'
  },
  listSection: {
    backgroundColor: 'inherit'
  },
  ul: {
    padding: 0
  },
  listItem: {
    borderRadius: 4,
    marginBottom: 4
  },
  nested: {
    borderRadius: 4,
    marginBottom: 4
    // marginLeft: theme.spacing(4)
  },
  itemText: {
    fontWeight: 'bold'
  },
  stickyHeader: {
    fontWeight: 'bold',
    backgroundColor: 'white'
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '#F8FAFD'
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    flex: 1,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(0),
      width: 'auto'
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 1),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    // paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    paddingLeft: theme.spacing(7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch'
    }
  },
  listItem: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(1),
    height: 69
  },
  listItemIcon: {
    color: theme.palette.secondary.main,
    padding: theme.spacing(1),
    minWidth: 40
  },
  listItemContainer: {
    '&:hover': {
      visibility: 'visible',
      '& $listItemSecondaryAction': {
        visibility: 'visible'
      }
    }
  },
  listItemSecondaryAction: {
    visibility: 'hidden'
  },
  iconLabel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const AddUserItem = ({ section, item, onListItemClick }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <ListItem
        button
        onClick={() => onListItemClick({ user: item })}
        key={`item-${section}-${item}`}
        className={classes.listItem}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <PersonAdd fontSize="default" />
        </ListItemIcon>
        <ListItemText>
          <Typography color="secondary" variant="h4">
            {t('create user')}
          </Typography>
        </ListItemText>
      </ListItem>
    </>
  );
};

const UserItem = ({
  data,
  section,
  item,
  onEditNutritionPlan,
  onEditWorkoutPlan,
  handleDelete,
  onListItemClick
}) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const handleClick = () => {
    setOpen(!open);
  };

  const { currentUser } = app.auth();
  const { name, membership, picture, email } = item;

  const { first, last } = name;
  const userName = `${first} ${last}`;
  const photoURL = picture.thumbnail;

  const secondaryActions = [
    {
      id: 'edit-nutrition-plan',
      title: 'Muuda toitumisplaani',
      icon: '🍏',
      onClick: onEditNutritionPlan
    },
    {
      id: 'edit-workout-plan',
      title: 'Muuda treeningplaani',
      icon: '🏋️‍♀️',
      onClick: onEditWorkoutPlan
    }
  ];

  return (
    <>
      <ListItem
        button
        onClick={() => onListItemClick({ user: item })}
        key={`item-${section}-${item}`}
        className={classes.listItem}
        classes={{
          container: classes.listItemContainer
        }}
      >
        <ListItemAvatar>
          <Avatar src={photoURL ?? currentUser?.photoURL} alt={userName} />
        </ListItemAvatar>
        <ListItemText
          primary={userName}
          // secondary={`${email ?? t('personal trainer')}`}
          style={{ fontWeight: 'bold' }}
          classes={{
            primary: classes.itemText
          }}
        />
        {onEditNutritionPlan && onEditWorkoutPlan && (
          <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
            {secondaryActions.map(({ title, id, icon, onClick }) => (
              <Tooltip title={title} key={id}>
                <IconButton
                  style={{
                    marginRight: 0
                  }}
                  classes={{
                    label: classes.iconLabel
                  }}
                  edge="end"
                  onClick={() => onClick(item)}
                >
                  <Box width={32} height={32}>
                    <Typography style={{ fontSize: 24 }}>{icon}</Typography>
                  </Box>
                </IconButton>
              </Tooltip>
            ))}
          </ListItemSecondaryAction>
        )}
      </ListItem>
    </>
  );
};

export default function PinnedSubheaderList({
  data,
  onAddUserPress,
  ...props
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const searchText = useSelector((state) => state.users.searchText);

  if (!Boolean(data.length)) {
    return <CircularProgress />;
  }

  return (
    <List className={classes.root} subheader={<li />}>
      <li className={classes.listSection}>
        <ul className={classes.ul}>
          {onAddUserPress && <AddUserItem onListItemClick={onAddUserPress} />}
          {_.filter(
            _.orderBy(data, (obj) => obj?.name?.first),
            (obj) =>
              (
                obj?.name?.first.toLowerCase() + obj?.name?.last?.toLowerCase()
              ).includes(searchText.toLowerCase())
          )?.map((item, index) => (
            <UserItem key={index} item={item} {...props} />
          ))}
        </ul>
      </li>
    </List>
  );
}
