import {
  Box,
  CardActionArea,
  makeStyles,
  Typography,
  useTheme
} from '@material-ui/core';
import Image from 'material-ui-image';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  uploadText: {
    color: theme.palette.text.hint,
    marginLeft: theme.spacing(1)
  }
}));

const ImagePicker = ({ setSelectedFile, defaultValue }) => {
  const [previewUrl, setPreviewImageUrl] = useState(null);
  const theme = useTheme();
  const { t } = useTranslation();

  const classes = useStyles();

  const hiddenFileInput = useRef(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    const previewUrl = URL.createObjectURL(selectedFile);
    setSelectedFile({ file: selectedFile, preview: previewUrl });
    setPreviewImageUrl(previewUrl);
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  useEffect(() => {
    return () => {
      setSelectedFile(null), setPreviewImageUrl(null);
    };
  }, []);

  return (
    <Box>
      <CardActionArea
        style={{
          height: 200,
          width: 300,
          borderRadius: 8,
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          backgroundColor: 'white'
        }}
        onClick={handleClick}
      >
        <input
          style={{ display: 'none' }}
          type="file"
          onChange={handleFileChange}
          name="img"
          accept=".png, .jpeg, .jpg"
          ref={hiddenFileInput}
        />
        <div>
          <Image
            aspectRatio={16 / 9}
            cover
            src={previewUrl || defaultValue}
            disableSpinner={!previewUrl}
            style={{
              position: 'undefined',
              paddingTop: 0
            }}
            imageStyle={{
              borderRadius: 8,
              width: 300,
              height: 200
            }}
          />
        </div>
        <Typography variant="h6" className={classes.uploadText}>
          {t('add image')}
        </Typography>
      </CardActionArea>
    </Box>
  );
};

export default ImagePicker;
