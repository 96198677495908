import { Box, Grid, Typography } from '@material-ui/core';
import { format, fromUnixTime } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const CheckIn = () => {
  const { t } = useTranslation();
  const logs = useSelector((state) => state.users.logs);

  return (
    <>
      <Box mb={5}>
        <Typography variant="h1">{t('check-in')}</Typography>
      </Box>

      <Grid container spacing={0}>
        {logs.map((item, index) => (
          <>
            <Grid item xs={1}>
              <Box
                display="flex"
                flex={1}
                height="100%"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <Typography variant="h3">Nädal</Typography>
                <Typography variant="h1">{logs.length - index}</Typography>
              </Box>
            </Grid>
            <Grid item xs={11}>
              <Box my={1} bgcolor="white" borderRadius={16} p={2}>
                <Typography variant="h5">
                  {format(fromUnixTime(item.createdAt.seconds), 'dd.MM.yyyy')}
                </Typography>
                {item.summary.map((log) => (
                  <Box mt={2}>
                    <Typography variant="h3">{log.label}</Typography>
                    <Typography variant="h4">{log.value}</Typography>
                  </Box>
                ))}
              </Box>
            </Grid>
          </>
        ))}
      </Grid>
    </>
  );
};

export default CheckIn;
