import { Box, Divider } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { func, object, string } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    paddingBottom: 0,
    paddingLeft: 0,
    boxShadow: 'none',
    backgroundColor: 'inherit'
  },
  image: {
    width: 363,
    height: 184
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    width: 363,
    height: 184,
    borderRadius: 8,
    objectFit: 'cover'
  },
  actions: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  divider: {
    width: '100%',
    marginTop: theme.spacing(2)
  },
  publish: {
    fontSize: 12,
    fontWeight: '700',
    color: theme.palette.common.white
  },
  unpublish: {
    fontSize: 12,
    fontWeight: '700',
    color: theme.palette.primary.main
  }
}));

const Description = ({ text }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [textOpen, setTextOpen] = useState(false);
  const [overflowActive, setOverflowActive] = useState(false);
  const textRef = useRef();

  const theme = useTheme();

  const isOverflowActive = (event) => {
    return (
      event.offsetHeight < event.scrollHeight ||
      event.offsetWidth < event.scrollWidth
    );
  };

  useEffect(() => {
    if (isOverflowActive(textRef.current)) {
      // setOverflowActive(true);
      return;
    }

    // setOverflowActive(false);
  }, [isOverflowActive]);

  return (
    <>
      <Box mt={1.5} ref={textRef}>
        <Typography
          variant="body1"
          style={{
            lineHeight: '20px'
          }}
        >
          {/* {!overflowActive ? text : text.slice(0, 200)} */}
          {text}
        </Typography>
      </Box>
      {/* {!textOpen && !overflowActive ? null : (
        <span
          onClick={() => setTextOpen(!textOpen)}
          style={{ cursor: 'pointer', color: '#00c696' }}
        >
          {!textOpen ? t('read_more') : textOpen && t('read_less')}
        </span>
      )} */}
    </>
  );
};

Description.propTypes = {
  text: string
};

export default Description;
