import React from 'react';
import { makeStyles, Box, Grid } from '@material-ui/core/';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ModalTitle from './ModalTitle';

const useStyles = makeStyles(theme => ({
  saveButton: {
    borderRadius: theme.spacing(2) / 1.5,
    borderWidth: 2,
    width: 112,
    height: 48,
    '&:hover': {
      borderWidth: 2
    }
  },
  title: {
    marginBottom: theme.spacing(3)
  },
  introText: {
    lineHeight: 1.2,
    marginBottom: theme.spacing(1),
    color: theme.palette.text.hint
  },
  divider: {
    borderTop: `1px solid ${theme.palette.divider}`,
    maxWidth: 800,
    marginTop: theme.spacing(2)
  }
}));

const ModalHeader = ({ title, introText, maybeButtonTitle }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const buttonTitle = maybeButtonTitle ?? t('save');

  return <ModalTitle title={title} introText={introText} />;
};

ModalHeader.propTypes = {
  title: PropTypes.string,
  introText: PropTypes.string,
  maybeButtonTitle: PropTypes.string
};

export default ModalHeader;
