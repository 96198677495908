import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { db } from '../../firebase';

export const updateUserWorkoutPlan = createAsyncThunk(
  'userWorkoutPlan/update',
  async ({ user, ...payload }) => {
    // const userId = auth.currentUser.uid;
    const { workouts, plan } = payload;
    const batch = db.batch();

    batch.set(
      db
        .collection('users')
        .doc(user.id)
        .collection('workout_plans')
        .doc(plan.id),
      plan
    );

    workouts.forEach((workout) => {
      batch.set(
        db

          .collection('users')
          .doc(user.id)
          .collection('workout_plans')
          .doc(workout.id)
          .collection('workouts')
          .doc(workout.id),
        workout
      );
    });

    // await db
    //   .collection('users')
    //   .doc(user.id)
    //   .collection('plans')
    //   .doc(payload.id)
    //   .set(plan);

    return batch.commit().then(() => ({ user, ...payload }));
  }
);

export const getUserWorkoutplan = createAsyncThunk(
  'userWorkoutPlan/get',
  async ({ workoutPlanId, id, ...rest }) => {
    // const userId = auth.currentUser.uid;
    let currentWorkout = null;

    const querySnapshot = await db
      .collection('users')
      .doc(id)
      .collection('workouts')
      .doc(workoutPlanId)
      .get();

    if (querySnapshot.exists) {
      currentWorkout = querySnapshot.data();
    }
    if (currentWorkout) {
      return { ...currentWorkout, user: { id, ...rest } };
    }
    return null;
  }
);

export const slice = createSlice({
  name: 'userWorkoutPlan',
  initialState: {
    data: null,
    isLoading: null,
    error: null
  },
  reducers: {
    setUserWorkoutPlan: (state, { payload }) => {
      state.data = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUserWorkoutplan.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getUserWorkoutplan.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getUserWorkoutplan.rejected, (state, error) => {
      state.isLoading = false;
    });
    builder.addCase(updateUserWorkoutPlan.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateUserWorkoutPlan.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    });
    builder.addCase(updateUserWorkoutPlan.rejected, (state, error) => {
      state.isLoading = false;
    });
  }
});

const { actions, reducer } = slice;

export const { setUserWorkoutPlan } = actions;

export default reducer;
