import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { default as User } from '@material-ui/icons/PermIdentityOutlined';
import clsx from 'clsx';
import { object, string } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  FiCard,
  FiCardActionArea,
  FiCardContent,
  FiCardMedia
} from './FullImageCard/FullImageCard';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 12,
    backgroundColor: theme.palette.common.white,
    boxShadow: 'none',
    boxShadow: theme.dropShadows[0]
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1
  },
  title: {
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 1,
    wordBreak: 'break-all',
    overflow: 'hidden'
  },
  statsIcon: {
    color: theme.palette.common.white,
    fontSize: 16
  },
  timelineDot: {
    padding: 0,
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    marginTop: 10,
    color: theme.palette.text.hint
  },
  focusHighlight: {
    backgroundColor: theme.palette.common.black
  },
  media: {
    objectFit: 'fill'
  }
}));

const EventCard = ({ className, item, ...rest }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { id, title, workoutCount, rating, usersCount, media } = item;

  return (
    <Link to={`/app/workouts/${id}`} style={{ textDecoration: 'none' }}>
      <FiCard className={clsx(classes.root, className)} {...rest}>
        <FiCardActionArea style={{ height: 175 }}>
          <FiCardMedia image={media} />
          <FiCardContent>
            <Box>
              <Grid container spacing={0}>
                <Grid className={classes.statsItem} item xs={12}>
                  <Box classes={{ root: classes.title }}>
                    <Typography
                      style={{ color: 'white', fontWeight: 'bolder' }}
                      display="block"
                      variant="h4"
                    >
                      {title}
                    </Typography>
                  </Box>
                </Grid>
                <Grid className={classes.statsItem} item>
                  <Typography
                    display="inline"
                    variant="body2"
                    style={{ color: 'white' }}
                  >
                    {`${workoutCount ?? 0} `}
                    {t('workout', { count: workoutCount ?? 0 }).toLowerCase()}
                  </Typography>
                  <Box flexGrow={1} />
                  {/* <Star className={classes.statsIcon} />
                  <Typography
                    display="inline"
                    variant="body2"
                    style={{ color: 'white' }}
                  >
                    {rating}
                  </Typography> */}
                  <Box mx={0.5} />
                  <User className={classes.statsIcon} />
                  <Typography
                    display="inline"
                    variant="body2"
                    style={{ color: 'white' }}
                  >
                    {usersCount || 0}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </FiCardContent>
        </FiCardActionArea>
      </FiCard>
    </Link>
  );
};

EventCard.propTypes = {
  className: string,
  item: object.isRequired
};

export default EventCard;
