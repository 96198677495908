import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import withStyles from '@material-ui/core/styles/withStyles';

export const FiCard = withStyles({
  root: {
    position: 'relative'
  }
})(Card);

export const FiCardActionArea = withStyles({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-end'
  }
})(CardActionArea);

export const FiCardActions = withStyles({
  root: {
    position: 'relative'
  }
})(CardActions);

export const FiCardContent = withStyles({
  root: {
    position: 'relative',
    backgroundColor: 'transparent',
    background: 'linear-gradient(180deg,transparent 10%,rgba(29,29,39,.5))',
    flex: 1
  }
})(CardContent);

export const FiCardMedia = withStyles({
  root: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    width: '100%'
  }
})(CardMedia);

export default {
  FiCard,
  FiCardActionArea,
  FiCardActions,
  FiCardContent,
  FiCardMedia
};
