import { Box, Divider, Drawer, List, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import BackButton from '../../components/BackButton';
import ProfileInfo from '../../components/ProfileInfo';
import { getUserLogs } from '../../features/users/usersSlice';
import NavItem from '../../layouts/DashboardLayout/NavBar/NavItem';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.lightBlue,
    display: 'flex',
    height: '100%',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    height: '100%',
    width: '100%',
    paddingLeft: 240,
    // paddingTop: 64,
    backgroundColor: theme.palette.background.lightBlue
    // [theme.breakpoints.up('lg')]: {
    //   paddingLeft: 310
    // }
  },
  contentContainer: {
    display: 'flex',
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.background.lightBlue
  },
  content: {
    flex: 1,
    overflow: 'scroll',
    height: '100%',
    backgroundColor: theme.palette.background.lightBlue
  },
  desktopDrawer: {
    width: 240,
    top: 64,
    height: 'calc(100% - 64px)'
  }
}));

const UserView = () => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(true);
  const [isMobileFilterOpen, setMobileFilterOpen] = useState(false);
  const [isOpen, setOpen] = useState(false);
  let location = useLocation();
  const user = location?.state?.user;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = () => {
      dispatch(getUserLogs({ userId: user.id }));
    };
    fetchData();
  }, []);

  const handleClose = () => {
    setMobileFilterOpen(false);
    setMobileNavOpen(false);
  };

  const userPath = `/app/user/${user?.name?.full
    ?.split(' ')
    .join('')
    .toLowerCase()}`;

  const items = [
    {
      href: userPath + '/profile',
      title: t('profile')
    },
    {
      href: userPath + '/check-in',
      title: t('check-in')
    }
  ];

  return (
    <div className={classes.root}>
      <Drawer
        anchor="left"
        classes={{ paper: classes.desktopDrawer }}
        open
        variant="persistent"
      >
        <Box px={1} pt={1}>
          <BackButton onClick={() => navigate('/app/users')} />
        </Box>
        <ProfileInfo
          name={user?.name?.full}
          imageUrl={user?.picture?.thumbnail}
          disabled
        />
        <Divider />
        <Box p={2}>
          <List>
            {items.map((item) => (
              <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                state={{ user }}
              />
            ))}
          </List>
        </Box>
      </Drawer>
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserView;
