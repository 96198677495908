import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  IconButton,
  InputAdornment,
  makeStyles,
  Typography
} from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import clsx from 'clsx';
import CircleIndicator from './CircleIndicator';
import { useController } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  textfield: {
    borderRadius: 8,
    background: 'white',
    border: `1px solid ${theme.palette.primary.surface}`,
    padding: '0.5rem',
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 400,
    '& textArea::placeholder': {
      color: theme.palette.text.hint
      // color: 'red'
      // opacity: 1
    }
  },
  textFieldSection: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  textFieldTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  textFieldPlacement: {
    marginLeft: theme.spacing(3),
    marginTop: theme.spacing(2)
  }
}));

const MultilineTextfield = ({
  placeholder,
  onClickRemoveAlternative,
  textFieldStyle,
  name,
  rules,
  control,
  defaultValue,
  isValid,
  title,
  ...rest
}) => {
  const classes = useStyles();

  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields }
  } = useController({
    name,
    control,
    rules,
    defaultValue
  });

  return (
    <>
      <div className={classes.textFieldTitle}>
        <Typography variant="subtitle2">{isValid ? '✅' : '❌'}</Typography>
        <Box mx={1} />
        <Typography variant="subtitle2">{title}</Typography>
      </div>
      <div className={classes.textFieldPlacement}>
        <InputBase
          inputRef={ref}
          onChange={onChange}
          onBlur={onBlur}
          name={name}
          className={clsx(classes.textfield, textFieldStyle)}
          placeholder={placeholder}
          defaultValue={defaultValue}
          multiline
          rows={5}
          fullWidth
          {...rest}
        />
      </div>
    </>
  );
};

MultilineTextfield.propTypes = {
  placeholder: PropTypes.string,
  textFieldStyle: PropTypes.string,
  onClickRemoveAlternative: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  isValid: PropTypes.bool,
  title: PropTypes.string
};

export default MultilineTextfield;
