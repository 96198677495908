import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';
import CircleIndicator from './CircleIndicator';
import { Validation } from './Validation';

const useStyles = makeStyles((theme) => ({
  textFieldTitle: {
    display: 'flex',
    alignItems: 'center'
  }
}));

const TextFieldTitle = ({ title, isValid }) => {
  const classes = useStyles();

  return (
    <div className={classes.textFieldTitle}>
      <Validation isValid={isValid} />
      <Typography variant="subtitle2">{title}</Typography>
    </div>
  );
};

TextFieldTitle.propTypes = {
  title: PropTypes.string,
  isValid: PropTypes.bool
};

export default TextFieldTitle;
