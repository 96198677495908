import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { oneOf, string } from 'prop-types';
import React from 'react';
import { default as Delete } from '@material-ui/icons/DeleteOutline';
import { default as Edit } from '@material-ui/icons/Edit';
import { default as Add } from '@material-ui/icons/Add';
// import Delete from 'src/icons/Delete';
// import Edit from 'src/icons/Edit';

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    color: theme.palette.text.hint
  },
  label: (type) => ({
    color: theme.palette.text.hint,
    // color: 'red',
    fontSize: 11,
    fontWeight: '700'
  })
}));
const ICON_TYPES = {
  EDIT: 'edit',
  DELETE: 'delete',
  ADD: 'add'
};
const DeleteButton = ({ type, title, ...props }) => {
  const classes = useStyles({ type });

  const Icon = () => {
    switch (type) {
      case ICON_TYPES.EDIT:
        return <Edit className={classes.deleteButton} />;
      case ICON_TYPES.DELETE:
        return <Delete className={classes.deleteButton} />;
      case ICON_TYPES.ADD:
        return <Add className={classes.deleteButton} />;
      default:
        return <Delete className={classes.deleteButton} />;
    }
  };

  return (
    <Button startIcon={<Icon />} {...props}>
      <Typography className={classes.label}>{title}</Typography>
    </Button>
  );
};

DeleteButton.propTypes = {
  title: string,
  type: oneOf(['edit', 'delete'])
};

export default DeleteButton;
