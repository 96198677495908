import { createSlice } from '@reduxjs/toolkit';

export const exercisesSlice = createSlice({
  name: 'exercises',
  initialState: {
    exercises: []
  },
  reducers: {
    setExercises: (state, action) => {
      state.filters = action.payload;
    }
  }
});

const { actions, reducer } = exercisesSlice;
export const { setExercises } = actions;

export default reducer;
