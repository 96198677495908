import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import enLocale from 'date-fns/locale/en-US';
import etLocale from 'date-fns/locale/et';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import { AuthProvider } from './Authentication';
import AuthorizedRoute from './AuthorizedRoute';
import IndexView from './components/IndexView';
import DashboardLayout from './layouts/DashboardLayout';
import MainLayout from './layouts/MainLayout';
import theme from './theme';
import OnboardingView from './views/auth/OnboardingView';
import NotFoundView from './views/errors/NotFoundView';
import EventDetailView from './views/eventdetail/EventDetailView';
import NutritionDetailView from './views/nutrition/NutritionDetailView';
import NutritionView from './views/nutrition/NutritionView';
import CheckIn from './views/users/UserProfile/CheckIn';
import Profile from './views/users/UserProfile/Profile';
import UserIndexView from './views/users/UserProfile/UserIndexView';
import UsersView from './views/users/UsersView';
import UserView from './views/users/UserView';
import WorkoutsView from './views/workoutplans/WorkoutsView';
import PhoneAuth from './views/auth/PhoneAuth';

etLocale.options.weekStartsOn = 1;
enLocale.options.weekStartsOn = 1;

const localeMap = {
  en: enLocale,
  et: etLocale
};

const App = () => {
  const { t } = useTranslation();
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap['en']}>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<MainLayout />}>
                <Route path="/" element={<Navigate to="login" />} />
                {/* <Route path="login" element={<SignInView />} /> */}
                <Route path="login" element={<PhoneAuth />} />
                <Route path="onboarding" element={<OnboardingView />} />
                <Route path="404" element={<NotFoundView />} />
                <Route path="*" element={<Navigate to="404" />} />
              </Route>
              <AuthorizedRoute path="app" element={<DashboardLayout />}>
                <Route path="/" element={<Navigate to="dashboard" />} />
                <Route path="users" element={<IndexView title={t('users')} />}>
                  <Route path="/" element={<UsersView />} />
                </Route>
                <Route path="/user" element={<UserView />}>
                  <Route path="/:user" element={<UserIndexView />}>
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/check-in" element={<CheckIn />} />
                  </Route>
                </Route>
                <Route
                  path="workouts"
                  element={<IndexView title={t('workout plans')} />}
                >
                  <Route path="/" element={<WorkoutsView />} />
                  <Route path="/:id" element={<EventDetailView />} />
                </Route>
                <Route
                  path="nutrition"
                  element={<IndexView title={t('nutrition')} />}
                >
                  <Route path="/" element={<NutritionView />} />
                  <Route path="/:id" element={<NutritionDetailView />} />
                </Route>
              </AuthorizedRoute>
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
};

export default App;
