import {
  AppBar,
  Box,
  IconButton,
  makeStyles,
  Toolbar
} from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import { default as FilterListIcon } from '@material-ui/icons/FilterList';
import { default as MenuIcon } from '@material-ui/icons/Menu';
import { default as SearchIcon } from '@material-ui/icons/Search';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import Logo from '../../../components/Logo';
import { setSearchText } from '../../../features/users/usersSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main
  },
  avatar: {
    width: 60,
    height: 60
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.25)'
    },
    marginLeft: 0,
    marginRight: 16,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto'
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch'
      }
    }
  }
}));

const TopBar = ({
  className,
  onMobileFilterOpen,
  onMobileNavOpen,
  onSignOut,
  ...rest
}) => {
  const classes = useStyles();
  let location = useLocation();
  let isFilterVisible = location.pathname === '/app/calendar';
  let isSearchVisible = location.pathname === '/app/users';
  const dispatch = useDispatch();

  const handleSearch = (event) => {
    const text = event.target.value;
    dispatch(setSearchText(text));
  };

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar>
        <IconButton color="inherit" onClick={onMobileNavOpen}>
          <MenuIcon />
        </IconButton>
        <Box flexGrow={1} />
        {isSearchVisible && (
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              inputProps={{ 'aria-label': 'search' }}
              onChange={handleSearch}
            />
          </div>
        )}
        {isFilterVisible && (
          <IconButton color="inherit" onClick={onMobileFilterOpen}>
            <FilterListIcon />
          </IconButton>
        )}
        <RouterLink to="/app/workouts">
          <Logo />
        </RouterLink>
        {/* <Hidden mdDown> */}
        {/* <GymList data={gyms} />
          <IconButton color='inherit'>
            <Badge badgeContent={usersOnline.length} color='secondary'>
              <PeopleIcon />
            </Badge>
          </IconButton> */}
        {/* <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="secondary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
        {/* <IconButton color="inherit" onClick={onSignOut}>
            <InputIcon />
          </IconButton> */}
        {/* </Hidden> */}
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
