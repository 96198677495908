import { Box, makeStyles, Typography } from '@material-ui/core';
import { string } from 'prop-types';
import React from 'react';
import ErrorMessage from './ErrorMessage';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(3)
  },
  introText: {
    lineHeight: 1.2,
    marginBottom: theme.spacing(0.5),
    color: theme.palette.text.hint
  },
  divider: {
    borderTop: `1px solid ${theme.palette.divider}`,
    width: '100%',
    marginTop: theme.spacing(2)
  }
}));

const ModalTitle = ({ title, introText }) => {
  const classes = useStyles();

  const numberOfRows = introText?.split('.').length;

  return (
    <>
      <Typography variant="h1" component="h1" className={classes.title}>
        {title || '-'}
      </Typography>
      {introText?.split('.').map((line, index) => (
        <Typography key={index} variant="body2" className={classes.introText}>
          {line && `${line}.`}
        </Typography>
      ))}
      {numberOfRows <= 2 ? <Box mt={5} /> : <Box mt={3.5} />}
      <hr className={classes.divider} />
      <Box position="absolute" mt={-0.5} ml={3}>
        <ErrorMessage errorMessage="Hello" />
      </Box>
    </>
  );
};

ModalTitle.propTypes = {
  title: string,
  introText: string
};

export default ModalTitle;
