import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { actionCodeSettings, auth, db } from '../../firebase';

export const getUserLogs = createAsyncThunk(
  'users/logs/get',
  async (payload) => {
    const snap = await db
      .collection('users')
      .doc(payload.userId)
      .collection('check-in')
      .orderBy('createdAt', 'desc')
      .get();

    const logs = snap.docs.map((item) => ({ id: item.key, ...item.data() }));

    return logs;
  }
);

export const updateUser = createAsyncThunk('users/update', async (payload) => {
  const usersRef = db.collection('users');

  await usersRef.doc(payload.id).set(payload, { merge: true });
  return payload;
});

export const deleteUser = createAsyncThunk('users/delete', async (payload) => {
  const usersRef = db.collection('users');

  await usersRef.doc(payload.id).delete();
  return payload;
});

export const createUser = createAsyncThunk(
  'users/createUser',
  async (payload) => {
    const { firstName, lastName, email, phone } = payload;
    //TODO: useCloudFunction to create user
    const usersRef = db.collection('users');
    const userId = usersRef.doc().id;

    usersRef.doc(userId).set(
      {
        id: userId,
        name: {
          first: firstName,
          last: lastName,
          full: `${firstName} ${lastName}`
        },
        picture: {
          thumbnail:
            'https://res.cloudinary.com/alvar-aronija/image/upload/v1701513749/gymply/avatar_p3qcbb_d9m2f8.png'
        },
        phone: '+' + phone
      },
      { merge: true }
    );

    //TODO: send sms to user
    // await auth.sendSignInLinkToEmail(email, actionCodeSettings(payload));
    return payload;
  }
);

export const usersSlice = createSlice({
  name: 'users',
  initialState: {
    searchText: '',
    isLoading: false,
    error: null,
    selectedUser: null,
    logs: []
  },
  reducers: {
    setSearchText: (state, action) => {
      state.searchText = action.payload;
    },
    setSelectedUser: (state, action) => {
      state.selectedUser = { ...state.selectedUser, ...action.payload };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(updateUser.pending, (state, { type, payload }) => {
      state.isLoading = true;
    });
    builder.addCase(updateUser.fulfilled, (state, { type, payload }) => {
      state.isLoading = false;
      state.selectedUser = { ...state.selectedUser, ...payload };
    });
    builder.addCase(updateUser.rejected, (state, error) => {
      state.isLoading = false;
      state.error = error;
    });
    builder.addCase(createUser.pending, (state, { type, payload }) => {
      state.isLoading = true;
    });
    builder.addCase(createUser.fulfilled, (state, { type, payload }) => {
      state.isLoading = false;
    });
    builder.addCase(createUser.rejected, (error) => {
      state.isLoading = false;
      state.error = error;
    });

    builder.addCase(deleteUser.pending, (state, { type, payload }) => {
      state.isLoading = true;
    });
    builder.addCase(deleteUser.fulfilled, (state, { type, payload }) => {
      state.isLoading = false;
    });
    builder.addCase(deleteUser.rejected, (error) => {
      state.isLoading = false;
      state.error = error;
    });

    builder.addCase(getUserLogs.pending, (state, { type, payload }) => {
      state.isLoading = true;
    });
    builder.addCase(getUserLogs.fulfilled, (state, { type, payload }) => {
      state.isLoading = false;
      state.logs = payload;
    });
    builder.addCase(getUserLogs.rejected, (error) => {
      state.isLoading = false;
      state.error = error;
    });
  }
});

const { actions, reducer } = usersSlice;

export const selectSelectedUser = (state) => state.users.selectedUser;
export const selectIsLoading = (state) => state.users.isLoading;

export const { setSearchText, setSelectedUser } = actions;

export default reducer;
