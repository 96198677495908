import { useEffect, useState } from 'react';
import { auth, db } from '../firebase';

export const useUser = () => {
  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    setLoading(true);
    const subscriber = subscribeToUser();
    return () => subscriber();
  }, []);

  const onResult = (snap) => {
    setLoading(false);

    if (!snap.exists) return;

    const data = snap.data();
    if (data) {
      setData(data);
    }
  };

  const onError = (error) => {
    setError(error);
    setLoading(false);
  };

  const subscribeToUser = () => {
    const userId = auth.currentUser.uid;
    return db.collection('users').doc(userId).onSnapshot(onResult, onError);
  };

  return [data, isLoading, error];
};
