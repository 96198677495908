import { Box, Grid, Hidden, makeStyles } from '@material-ui/core/';
import Modal from '@material-ui/core/Modal';
import { nanoid } from 'nanoid';
import { array, bool, func, instanceOf, object, string } from 'prop-types';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActionModal from '../ActionModal/ActionModal';
import ModalHeader from '../ModalHeader';
import ModalNavBar from '../ModalNavBar';
import Sections from '../Sections';
import MealForm from './MealForm';

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    padding: theme.spacing(5, 5, 0, 5)
  },
  contentContainer: {
    backgroundColor: theme.palette.background.lightBlue
  },
  wrapper: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.lightBlue,
    paddingBottom: theme.spacing(2)
  },
  subTitle: {
    marginTop: theme.spacing(3)
  },
  textFieldSingle: {
    display: 'block',
    margin: theme.spacing(2, 0, 0, 0),
    backgroundColor: theme.palette.common.white,
    width: '100%',
    borderRadius: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    height: 52
  },
  textFieldMulti: {
    height: 'auto'
  },
  inputText: {
    fontSize: 14,
    fontWeight: 400,
    width: 700,
    paddingTop: 15
  },
  addOutcomeButton: {
    minWidth: 800
  },
  modal: {}
}));

const meal_info = {
  id: nanoid(),
  title: ''
};

const MealModal = ({ isOpen, onClose, defaultValues, onSave, isLoading }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const formRef = useRef();
  const [sectionToDelete, setSectionToDelete] = useState(null);
  const [meals, setMeals] = useState(defaultValues ?? [meal_info]);
  const [currentIngredient, setCurrentIngredient] = useState(meals[0]);

  const handleFormChange = (value) => {
    setMeals((prevState) =>
      prevState.map((item) =>
        item.id === currentIngredient?.id
          ? { ...item, ...value, id: currentIngredient.id }
          : item
      )
    );
  };

  const handleSetMealValid = ({ id, isValid }) => {
    setMeals((prevState) =>
      prevState.map((item) => (item.id === id ? { ...item, isValid } : item))
    );
  };

  const renderForm = (item, index) => {
    return (
      <MealForm
        data={item}
        index={index}
        ref={formRef}
        onFormChange={handleFormChange}
        setFormValid={handleSetMealValid}
      />
    );
  };

  const handleAddSection = () => {
    const newMeal = {
      id: nanoid(),
      title: '',
      index: meals.length
    };
    setMeals((prevState) => [...prevState, newMeal]);
    setCurrentIngredient(newMeal);
  };

  const shouldDeleteIngredient = async () => {
    setMeals((prevState) => [
      ...prevState.filter((item) => item.id !== sectionToDelete.id)
    ]);
    setCurrentIngredient(meals[meals.length - 2]);
    setSectionToDelete(null);
  };

  const handleDelete = async (id, index) => {
    setSectionToDelete({ id, index });
  };

  const handleSectionItemClick = ({ id, index }) => {
    setCurrentIngredient({ id, index });
  };

  const isFormValid =
    meals && meals.length && meals?.every((item) => item.isValid);

  return (
    <>
      <Modal
        open={isOpen}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modal}
      >
        <Box className={classes.wrapper}>
          <Grid container>
            <Grid item xs={12}>
              <Box mx={7} mt={3}>
                <ModalNavBar
                  onSave={() => onSave(meals)}
                  handleClose={onClose}
                  isButtonDisabled={!isFormValid}
                  isLoading={isLoading}
                />
              </Box>
              <Box my={2} />
            </Grid>
            <Grid item xs={12} container>
              <Grid item xs={3}></Grid>
              <Grid item xs={7}>
                <ModalHeader title={meals[0].title} />
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3}>
              <Hidden smDown>
                <Sections
                  title={t('meal')}
                  addButtonTitle={t('addIngredient')}
                  sections={meals}
                  selectedIndex={currentIngredient?.index ?? 0}
                  onAddSectionClick={handleAddSection}
                  onSectionItemClick={handleSectionItemClick}
                  onDelete={handleDelete}
                />
              </Hidden>
            </Grid>

            <Grid
              item
              xs={7}
              container
              style={{
                maxHeight: 700,
                overflow: 'scroll',
                paddingBottom: 100
              }}
            >
              <Grid item xs={12}>
                {meals?.map((item, index) => (
                  <Box
                    key={item.id}
                    hidden={item.id !== currentIngredient?.id}
                    pr={3}
                  >
                    {renderForm(item, index)}
                  </Box>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <ActionModal
        open={sectionToDelete}
        emoji="🗑"
        title={t('delete ingredient')}
        description={t('delete ingredient desc')}
        primaryButtonText={t('delete')}
        onPrimaryAction={shouldDeleteIngredient}
        onSecondaryAction={() => setSectionToDelete(null)}
      />
    </>
  );
};

MealModal.propTypes = {
  shouldEdit: bool,
  introText: string,
  courseID: string,
  isOpen: bool,
  children: instanceOf(Element),
  handleClose: func,
  onSave: func,
  onSave: func,
  onAddSectionClick: func,
  sections: array,
  onDelete: func,
  handleSelectSection: func,
  selectedSection: object,
  setSections: func,
  sectionsToAdd: func,
  sectionsToDelete: func,
  onClickBackButton: func,
  saveLessonDialogOpen: bool,
  closeSaveLessonDialog: func,
  setShouldEditLesson: func,
  isLoading: bool
};

export default MealModal;
