import { Box, Grid, makeStyles } from '@material-ui/core/';
import { func, object } from 'prop-types';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useIngredients } from '../../firestore';
import MultilineTextfield from '../MultilineTextfield';
import TextFieldSection from '../TextFieldSection';

const useStyles = makeStyles((theme) => ({
  textFieldSingle: {
    display: 'block',
    margin: theme.spacing(2, 0, 0, 0),
    backgroundColor: theme.palette.common.white,
    width: '100%',
    borderRadius: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    height: 52
  },
  textFieldMulti: {
    height: 'auto',
    marginLeft: theme.spacing(2)
  },
  inputText: {
    fontSize: 14,
    fontWeight: 400,
    width: 700,
    paddingTop: 15
  },
  uploadVideo: {
    fontWeight: '700',
    fontSize: 11
  }
}));

const MealForm = ({ data, index, onFormChange, setFormValid }, ref) => {
  const { ingredients } = useIngredients();
  const { t } = useTranslation();
  const {
    control,
    watch,
    trigger,
    setValue,
    formState: { isValid, errors }
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: data ?? {
      title: '',
      description: ''
    }
  });

  useEffect(() => trigger(), []);
  useEffect(() => setFormValid({ id: data.id, isValid }), [isValid, data.id]);

  useEffect(() => {
    const subscription = watch(onFormChange);
    return () => subscription.unsubscribe();
  }, [watch, onFormChange]);

  return (
    <>
      <TextFieldSection
        title={index === 0 ? t('title') : t('ingredient name')}
        placeholder={
          index === 0 ? t('name placeholder') : t('meal name placeholder')
        }
        autocomplete={index !== 0}
        autocompleteData={ingredients}
        isValid={!errors.title}
        control={control}
        rules={{
          required: {
            value: true,
            message: t('error.required')
          }
        }}
        name="title"
        defaultValue={data?.title ?? 'Title'}
        setValue={setValue}
      />
      {index !== 0 && (
        <Grid container>
          <Grid item xs={4}>
            <TextFieldSection
              title={t('amount')}
              placeholder={t('add amount')}
              control={control}
              name="amount"
              type="number"
              isValid={!errors.amount}
              defaultValue={data?.amount}
              rules={{
                required: {
                  value: true,
                  message: t('error.required')
                },
                min: {
                  value: 1,
                  message: t('error.minValue')
                },
                max: {
                  value: 10000,
                  message: t('error.maxValue')
                }
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextFieldSection
              title={t('unit')}
              placeholder={t('add unit')}
              control={control}
              isValid={!errors.unit}
              name="unit"
              defaultValue={data?.unit}
              rules={{
                required: {
                  value: true,
                  message: t('error.required')
                },
                min: {
                  value: 1,
                  message: t('error.minValue')
                },
                max: {
                  value: 99,
                  message: t('error.maxValue')
                }
              }}
            />
          </Grid>
        </Grid>
      )}
      <Box mt={3} />
      <MultilineTextfield
        id="description"
        control={control}
        title={t('description')}
        placeholder={t('description here')}
        defaultValue={data?.description ?? ''}
        isValid={!errors.description}
        name="description"
        rules={{ required: false }}
      />
    </>
  );
};
MealForm.propTypes = {
  onStateChange: func,
  state: object
};

export default MealForm;
