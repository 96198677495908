import { Box, Container, Grid, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import NutritionList from '../../components/EventList';
import NutritionCard from '../../components/NutritionCard';
import NutritionModal from '../../components/NutritionModal/NutritionModal';
import Page from '../../components/Page';
import { addNewNutritionPlan } from '../../features/nutrition-plans/slice';
import useNutritionPlans from '../../firestore/useNutritionPlans';
import useToggle from '../../hooks/useToggle';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.lightBlue,
    minHeight: '100%',
    height: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

const NutritionView = () => {
  const classes = useStyles();
  const [open, toggleOpen] = useToggle(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const { data: nutritionPlan, loading } = useNutritionPlans();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedItem) {
      handleClickOpen();
    }
  }, [selectedItem]);

  const handleClickOpen = () => {
    toggleOpen();
  };

  const handleClose = () => {
    toggleOpen();
    setSelectedItem(null);
  };

  const handleAddClass = () => {
    dispatch(addNewNutritionPlan());
    handleClickOpen();
  };

  return (
    <Page className={classes.root} title="Customers">
      <Container>
        <Box>
          {loading && (
            <Grid container spacing={2}>
              <Grid item lg={3} md={4} xs={6}>
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height={175}
                  style={{ borderRadius: 12 }}
                />
              </Grid>
              <Grid item lg={3} md={4} xs={6}>
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height={175}
                  style={{ borderRadius: 12 }}
                />
              </Grid>
              <Grid item lg={3} md={4} xs={6}>
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height={175}
                  style={{ borderRadius: 12 }}
                />
              </Grid>
            </Grid>
          )}
          {!loading && (
            <NutritionList
              onAddClass={handleAddClass}
              title={t('addNutrition')}
            >
              {nutritionPlan.map((item) => (
                <Grid item key={item.id} lg={3} md={4} xs={6}>
                  <NutritionCard item={item} />
                </Grid>
              ))}
            </NutritionList>
          )}
        </Box>

        {open && <NutritionModal isOpen={true} handleClose={handleClose} />}
      </Container>
    </Page>
  );
};

export default NutritionView;
