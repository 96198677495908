// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
//TODO: use .env

const firebaseConfig = {
  apiKey: 'AIzaSyBQdVcrfE_Unvdkr0hfzzy15qdI6ORTC0M',
  authDomain: 'gymply-ef1f7.firebaseapp.com',
  projectId: 'gymply-ef1f7',
  storageBucket: 'gymply-ef1f7.appspot.com',
  messagingSenderId: '965953179148',
  appId: '1:965953179148:web:0d0520918024cf885be829',
  measurementId: 'G-PL5S0ZJFRY'
};

const app = firebase.initializeApp(firebaseConfig);

export var db = app.firestore();
export var storage = app.storage();
export var auth = app.auth();

export const actionCodeSettings = ({ email, firstName, lastName }) => ({
  // The URL to redirect to for sign-in completion. This is also the deep
  // link for mobile redirects. The domain (www.example.com) for this URL
  // must be whitelisted in the Firebase Console.
  url: `https://dev--gymply.netlify.app/login?email=${email}&firstName=${firstName}&lastName=${lastName}`,
  iOS: {
    bundleId: 'com.gymply.dev'
  },
  android: {
    packageName: 'com.gymply.app.dev.android',
    installApp: true
  },
  // This must be true.
  handleCodeInApp: true,
  dynamicLinkDomain: 'gymply.page.link'
});

export default app;
