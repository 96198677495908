import {
  Button as MUIButton,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import clsx from 'clsx';
import { bool, string } from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 183,
    minHeight: 48,
    borderRadius: 12,
    padding: 0,
    borderWidth: 2,
    fontSize: 12,
    fontWeight: '700',
    color: theme.palette.common.white
  },
  outlined: {
    color: theme.palette.secondary.main,
    '&:hover': {
      borderWidth: 2
    }
  }
}));

const Button = ({ children, variant, buttonStyle, loading, ...props }) => {
  const classes = useStyles();
  return (
    <MUIButton
      classes={{
        root: clsx(
          classes.root,
          variant === 'outlined' && classes.outlined,
          buttonStyle
        )
      }}
      color="primary"
      variant={variant || 'contained'}
      {...props}
    >
      {loading ? <CircularProgress style={{ color: 'white' }} /> : children}
    </MUIButton>
  );
};

Button.propTypes = {
  children: string,
  variant: string,
  buttonStyle: string,
  loading: bool
};

export default Button;
