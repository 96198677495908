import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setSelectedPlan } from '../features/workoutplans/slice';
import app from '../firebase';

const useEventById = (id) => {
  const [data, setData] = useState({
    error: null,
    loading: true,
    event: {}
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = () => {
      let obj = {};
      try {
        app
          .firestore()
          .collection('workout_plans')
          .doc(id)
          .onSnapshot(async (snapshot) => {
            const snaps = await snapshot.ref
              .collection('workouts')
              .orderBy('index', 'asc')
              .get();
            const workouts = snaps.docs.map((snap) => ({
              id: snap.id,
              ...snap.data()
            }));
            const event = snapshot.data();
            dispatch(setSelectedPlan({ id: snapshot.id, ...event, workouts }));
            setData({
              loading: false,
              error: null,
              event: { id: snapshot.id, ...event, workouts }
            });
          });
      } catch (error) {
        setData({
          error,
          loading: false,
          event: {}
        });
      }
    };

    unsubscribe();
  }, []);

  return data;
};

export default useEventById;
