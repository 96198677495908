import {
  Box,
  Card,
  CardActionArea,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import { arrayOf, object } from 'prop-types';
import React from 'react';
import { PlusCircle as AddIcon } from 'react-feather';
import { useTranslation } from 'react-i18next';
import EventCard from '../EventCard';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 12,
    backgroundColor: theme.palette.common.white,
    boxShadow: 'none',
    boxShadow: theme.dropShadows[0]
  }
}));

const EventList = ({ onAddClass, data, title, children }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={3} md={4} xs={6}>
          <Card className={classes.root}>
            <CardActionArea onClick={onAddClass}>
              <Box
                height={175}
                width="100%"
                justifyContent="center"
                alignItems="center"
                display="flex"
                flexDirection="column"
              >
                <AddIcon color="#7672ff" />
                <Box height={16} />
                <Typography color="secondary" variant="h4">
                  {title ?? t('create event')}
                </Typography>
              </Box>
            </CardActionArea>
          </Card>
        </Grid>
        {children ??
          data.map((item) => (
            <Grid item key={item.id} lg={3} md={4} xs={6}>
              <EventCard item={item} />
            </Grid>
          ))}
      </Grid>
    </>
  );
};

EventList.propTypes = {
  data: arrayOf(object)
};

export default EventList;
