import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { AuthContext } from '../../Authentication';
import { updateSelectedPlan } from '../../features/workoutplans/slice';
import { db } from '../../firebase';
import { FALLBACK_URLS } from '../../utils';
import AddEventModal from '../AddEventModal/AddEventModal';

const PlanModal = (props) => {
  const [isLoading, setLoading] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const dispatch = useDispatch();
  const { title, description, media, isValid } = useSelector(
    (state) => state.plans.selectedPlan
  );

  const navigate = useNavigate();

  const uploadData = async (obj) => {
    if (props.defaultValues) {
      shouldUpdateData(obj);
    } else {
      try {
        const ref = await db.collection('workout_plans').add({
          title,
          description,
          media: FALLBACK_URLS.WORKOUT,
          createdBy: {
            id: currentUser.uid,
            name: currentUser.displayName ?? ''
          },
          rating: 0
        });

        navigate(`/app/workouts/${ref.id}`);
      } catch (error) {}
    }
  };

  const shouldUploadData = (data) => uploadData(data);
  const shouldUpdateData = () => {
    return db
      .collection('workout_plans')
      .doc(props.defaultValues.id)
      .set({ title, description, media }, { merge: true });
  };

  const handleFieldChange = (value) => {
    dispatch(
      updateSelectedPlan({
        title: value.title,
        description: value.description,
        isValid: isValid
      })
    );
  };

  const handleSave = async () => {
    setLoading(true);

    try {
      if (props.defaultValues) {
        await shouldUpdateData();
      } else {
        await shouldUploadData();
      }
    } catch (error) {
      console.log({ error });
    } finally {
      props.handleClose();
    }
  };

  return (
    <AddEventModal
      {...props}
      handleSave={handleSave}
      onChange={handleFieldChange}
      isValid={isValid}
      isLoading={isLoading}
    />
  );
};

export default PlanModal;
