import {
  Box,
  IconButton,
  ListItemSecondaryAction,
  Typography,
  useTheme
} from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { default as Delete } from '@material-ui/icons/Delete';
import { default as DragIndicatorIcon } from '@material-ui/icons/DragIndicator';
import clsx from 'clsx';
import { func, number, object, string } from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteButton from './DeleteButton';

const useStyles = makeStyles((theme) => ({
  firstItem: {
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1)
  },
  lastItem: {
    borderBottomLeftRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
    borderTop: '1px solid',
    borderColor: theme.palette.divider
  },
  listItemIcon: {
    minWidth: 32
  },
  nested: {
    height: 50,
    '&:hover': {
      visibility: 'visible',
      '& $listItemSecondaryAction': {
        visibility: 'visible'
      }
    }
  },
  listItem: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(1),
    height: 52
  },
  listContainer: {
    padding: 0,
    marginBottom: theme.spacing(1)
  },
  section: {
    '&:hover': {
      visibility: 'visible',
      '&:hover $listItemSecondaryAction': {
        visibility: 'visible'
      }
    }
  },
  listItemSecondaryAction: {
    visibility: 'hidden',
    right: theme.spacing(2)
  },
  itemText: {
    ...theme.typography.h5
  },
  deleteButton: {
    color: theme.palette.text.hint
  },
  label: {
    color: theme.palette.text.hint,
    fontSize: 11,
    fontWeight: '700'
  },
  addSectionText: {
    fontWeight: '700'
  },
  listContainer: {
    '&:hover': {
      visibility: 'visible',
      '& $listItemSecondaryAction': {
        visibility: 'visible'
      }
    },
    marginBottom: theme.spacing(1)
  },
  listItemText: {
    flex: 1
  }
}));

const Title = ({ children }) => (
  <Typography variant="h5">{children}</Typography>
);

const Subtitle = ({ children }) => (
  <Typography variant="subtitle1">{children}</Typography>
);

const Lesson = React.forwardRef(
  ({ item, index, onDelete, onEdit, onClick, onAddSection, ...props }, ref) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();

    const [open, setOpen] = useState(false);
    const {
      exercises,
      sections,
      totalLength,
      sectionsCount,
      title,
      isValid,
      id
    } = item ?? {
      sections: [],
      totalLength: 0,
      sectionsCount: 0,
      title: t('add workout'),
      isValid: false,
      id: 'add'
    };

    const handleClick = () => {
      setOpen(!open);
    };

    const handleLessonDelete = () => {
      // setDeleteLessonDialogOpen(true);
    };

    const handleLessonEdit = ({ section, index }) => {
      // onEdit({ lesson: item, section, index });
    };

    const handleAddSection = ({ section, index }) => {
      // onAddSection({ lesson: item, section, index });
    };

    const renderSections = () => {
      return exercises?.map((section, index) => (
        <ListItem
          key={index}
          classes={{ container: classes.section }}
          className={clsx(classes.nested, index === 0 && classes.firstItem)}
        >
          <ListItemText
            primary={
              <Typography variant="subtitle2">{section.title}</Typography>
            }
          />
          <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
            <DeleteButton
              type="edit"
              title={t('redigera')}
              onClick={() => handleLessonEdit({ section, index })}
            />
          </ListItemSecondaryAction>
        </ListItem>
      ));
    };

    return (
      <>
        <div {...props} ref={ref}>
          <ListItem
            className={classes.listItem}
            classes={{
              container: classes.listContainer
            }}
            button
            onClick={() => onClick({ id, title })}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <DragIndicatorIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ root: classes.listItemText }}
              primary={<Title>{title}</Title>}
            />
            <ListItemSecondaryAction
              className={classes.listItemSecondaryAction}
            >
              <IconButton onClick={() => onDelete(id)}>
                <Delete style={{ color: theme.palette.text.hint }} />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </div>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box pl={1.2} mb={1}>
            <ListItem className={clsx(classes.nested, classes.lastItem)}>
              <ListItem className={clsx(classes.nested)}>
                <DeleteButton
                  type="add"
                  title={t('add exercise')}
                  onClick={handleLessonDelete}
                />
                <ListItemText />
                <DeleteButton
                  title={t('delete workout')}
                  onClick={handleLessonDelete}
                />
              </ListItem>
            </ListItem>
          </Box>
        </Collapse>
      </>
    );
  }
);

Lesson.propTypes = {
  item: object,
  onDelete: func,
  onEdit: func,
  onAddSection: func,
  index: number
};

Title.propTypes = {
  children: string
};

Subtitle.propTypes = {
  children: string
};

export default Lesson;
