import { Box } from '@material-ui/core';
import React from 'react';
import { Outlet } from 'react-router-dom';

const UserIndexView = () => {
  return (
    <Box display="flex" width="100%" height="100%" p={3} flexDirection="column">
      <Outlet />
    </Box>
  );
};

export default UserIndexView;
