import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CreateUserModal from '../../../components/CreateUserModal/CreateUserModal';
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton';
import TextField from '../../../components/TextField';
import {
  deleteUser,
  selectIsLoading,
  selectSelectedUser
} from '../../../features/users/usersSlice';
import useToggle from '../../../hooks/useToggle';
import ActionModal from '../../../components/ActionModal/ActionModal';
import firebase from 'firebase/app';
import { useNavigate } from 'react-router';

const Profile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isEditing, toggleEditing] = useToggle();
  const [isDeleting, toggleDeleteUser] = useToggle();
  const user = useSelector(selectSelectedUser);
  const isLoading = useSelector(selectIsLoading);
  const { name, email, id, phone } = user;
  const dispatch = useDispatch();

  const isCurrentUser = id === firebase.auth().currentUser.uid;

  const handleDeleteUser = async () => {
    await dispatch(deleteUser({ id })).unwrap();
    toggleDeleteUser();
    navigate(-1);
  };

  return (
    <>
      <Typography variant="h1">{t('profile')}</Typography>
      <Box my={3} />
      <Box mb={1} ml={1}>
        <Typography variant="subtitle2">{t('name.firstName')}</Typography>
      </Box>
      <TextField value={name.first} disabled />
      <Box mb={1} ml={1} mt={2}>
        <Typography variant="subtitle2">{t('name.lastName')}</Typography>
      </Box>
      <TextField value={name.last} disabled />
      <Box mb={1} ml={1} mt={2}>
        <Typography variant="subtitle2">{t('phone')}</Typography>
      </Box>
      <TextField value={phone} disabled />
      <Box my={3} />
      <Grid container spacing={1}>
        <Grid item xs={2}>
          <PrimaryButton onClick={toggleEditing}>{t('edit')}</PrimaryButton>
        </Grid>
        {!isCurrentUser && (
          <Grid item xs={2}>
            <PrimaryButton secondary onClick={toggleDeleteUser}>
              {t('delete')}
            </PrimaryButton>
          </Grid>
        )}
      </Grid>
      {isEditing && (
        <CreateUserModal
          isOpen={true}
          handleClose={toggleEditing}
          defaultValues={{
            firstName: name.first,
            lastName: name.last,
            email,
            id,
            phone
          }}
        />
      )}
      {isDeleting && (
        <ActionModal
          open
          title={t('deleteUser')}
          description={t('deleteUserDesc')}
          emoji="🗑"
          primaryButtonText={t('delete')}
          secondaryButtonText={t('cancel')}
          onPrimaryAction={handleDeleteUser}
          onSecondaryAction={toggleDeleteUser}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default Profile;
