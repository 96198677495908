import { Box, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { string } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';
import Page from './Page';
import ViewHeader from './ViewHeader';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.lightBlue
  }
}));

const IndexView = ({ title }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();

  const shouldHideHeader =
    location.pathname.includes('/app/workouts/') ||
    location.pathname.includes('/app/nutrition/') ||
    location.pathname.includes('/app/users/');
  return (
    <Page title={`Gymply- ${title}`}>
      <Container
        className={classes.root}
        style={{ padding: 0, paddingRight: 0, paddingBottom: 0 }}
      >
        {!shouldHideHeader && (
          <Box mx={3}>
            <ViewHeader title={title} subtitle="" />
          </Box>
        )}
        <Outlet />
      </Container>
    </Page>
  );
};

IndexView.propTypes = {
  title: string
};

export default IndexView;
